import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const updateMembers = async (id: string, role: string) => {
  return await axios.put(`members/${id}`, {role})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateMembers;