import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getMembers = async (
  setData: React.Dispatch<React.SetStateAction<{
    content: any[];
    totalElements: number;
    totalPages: number;
  }>>,
  identification: string,
  page: number,
  size: number
) => {
  return await axios.get(`members/search-page?identification=${identification}&page=${page}&size=${size}`)
    .then((response) => setData(response.data.data))
    .catch(() => {});
};

export default getMembers;