import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const createMembers = async (identification: string, role: string) => {
  return await axios.post('members', {identification, role})
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createMembers;