import { useState, useEffect } from 'react';
// @form
import { useController, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form';
// styles
import { Label, ButtonFields, Message } from './styles';

// ----------------------------------------------------------------------

interface Props extends React.InputHTMLAttributes<HTMLInputElement>{
  label: string;
  action: JSX.Element;
  exists?: {
    result: boolean;
    message: string;
  };
};

// ----------------------------------------------------------------------

const ButtonField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name, control, label, action, exists, ...other
}: Props & UseControllerProps<TFieldValues, TName>) => {

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  const [ message, setMessage ] = useState({ type: null, content: null });

  useEffect(() => {
    if ( error && error.type !== 'required' && error.type !== 'typeError' ) {
      setMessage({ type: 'error', content: error.message });
    } else if ( exists?.message ) {
      if ( exists.result === false ) return setMessage({ type: 'info', content: exists.message });
      setMessage({ type: 'error', content: exists.message });
    } else {
      setMessage({ type: null, content: null });
    }
  }, [error, exists]);

  return (
    <div>
      <Label>{ label }</Label>
      <ButtonFields $error={error ? true : false}>
        <input {...other} {...field} />
        { action }
      </ButtonFields>

      { message.content &&
        <Message type={message.type}>{ message.content }</Message>
      }
    </div>
  );
};

export default ButtonField;