import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';

// ----------------------------------------------------------------------

export const Header = styled.header`
  padding: 0 40px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 5px 0px #1C252E08;
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
  z-index: 1;

  > div:first-child {
    display: flex;
    align-items: center;

    > div {
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -0.5px;
      color: ${({theme}) => theme.colors.grayscale[700]};
      margin-right: 120px;
    }
  }

  > div:last-child > button {
    width: 36px;
    height: 36px;
    background-image: url(/assets/user.svg);
    background-size: 23px 23px;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 50%;

    &:hover, &:active {
      background-color: ${({theme}) => theme.colors.grayscale[500]}1A;
    }
  }
    
  ${({theme}) => theme.media.md`
    > div:first-child > div {
      margin-right: 0;
    }
  `};
`;

export const Link = styled(ReactLink)<{ $active: boolean }>`
  font-size: 18px;
  font-weight: 500;
  margin-left: 60px;

  &:hover, &:active {
    color: ${({theme}) => theme.colors.primary.main};
  }

  ${(props) => props.$active && `
    font-weight: 600;
    color: ${props.theme.colors.primary.main};
  `};
`;

export const Main = styled.main`
    padding-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
`;