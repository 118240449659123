import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//components
import { TextField, PasswordField } from '../components/hook-form';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_ID, REGEXP_ID_START, REGEXP_PASSWORD } from '../utils/regexp';
// styles
import { LoginContainer, Message, PrimaryButton } from '../styles';
// apis
import { login } from '../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  id: yup.string()
    .required()
    .matches(REGEXP_ID)
    .matches(REGEXP_ID_START),
  password: yup.string()
    .required()
    .matches(REGEXP_PASSWORD)
});

interface FormValues {
  id?: string;
  password?: string;
};


// ----------------------------------------------------------------------

function LoginPage() {
  const navigate  = useNavigate();

  const {
    control,
    reset,
    setValue,
    setFocus,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: '',
      password: ''
    }
  });

  const [ message, setMessage ] = useState(null);

  const onError = (error: any) => {
    setMessage('아이디 또는 비밀번호를 다시 한번 확인해 주세요.');
    if ( Object.keys(error).includes('id') ) {
      reset();
      setTimeout(() => {
        setFocus('id');
      }, 50);
    } else {
      setValue('password', '');
      setFocus('password');
    }
  };

  const onSubmit = async (data: FormValues) => {
    await login(data.id, data.password)
      .then(() => {
        return navigate('/dashboard', {state: { submit: true }});
      })
      .catch((error) => {
        setMessage(error.message);
        setValue('password', '');
        setFocus('password');
      });
  };

  return (
    <LoginContainer>
      <div>Lab Scale Tester</div>

      <form onSubmit={ handleSubmit(onSubmit, onError) }>
        <TextField name='id' control={control} label='아이디' type='text' maxLength={30} autoComplete='username' />

        <PasswordField
          name='password'
          control={control}
          label='비밀번호'
          maxLength={30}
          autoComplete='current-password'
        />

        <Message>{ message }</Message>
        
        <PrimaryButton type='submit'>로그인</PrimaryButton>
      </form>
    </LoginContainer>
  );
}

export default LoginPage;