import { ReactNode } from 'react';
import { ThemeProvider as Theme } from 'styled-components';
import theme from './theme';
import GlobalStyles from './global';

// ----------------------------------------------------------------------

import 'devextreme/dist/css/dx.material.blue.light.css';
import './dx-styles.css';
export * from './styles';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <Theme theme={theme}>
      <GlobalStyles />
      { children }
    </Theme>
  );
}