import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
// redux
import { Provider } from 'react-redux';
import store from './store';
// styles
import ThemeProvider from './styles';

// ----------------------------------------------------------------------

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
