import { Overlay, Loader } from './styles';

// ----------------------------------------------------------------------

export default function Loaders() {
  return (
    <Overlay>
      <Loader />
    </Overlay>
  );
}