import { useState, useEffect } from 'react';
// components
import Breadcrumbs from '../components/breadcrumbs';
import TableInfo from '../components/table-info';
import Pagination from '../components/pagination';
import Dialog from '../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, ContentBox, SearchWrapper, PrimaryButton, DownloadButton } from '../styles';
// apis
import { getDownloads, download } from '../apis';

// ----------------------------------------------------------------------

const sizes = [10, 20, 30];

// ----------------------------------------------------------------------

function DownloadPage() {

  const [ data, setData ] = useState({ content: [], totalElements: 0, totalPages: 1 });
  const { content: rows, totalElements: total, totalPages: pages } = data;

  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  const [ message, setMessage ] = useState(null);
  const handleMessage = () => setMessage(null);

  useEffect(() => {
    handleSearch();
  }, []);

  const handlePage = (value: number) => {
    setPage(value-1);
    getDownloads(setData, keyword, value-1, size);
  };

  const handleSize = (value: number) => {
    setPage(0);
    setSize(value);
    getDownloads(setData, keyword, 0, value);
  };

  const handleSearch = () => {
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    setPage(0);
    getDownloads(setData, keyword, 0, size);
  };

  const handleDownload = async (data: any) => {
    await download(data.listId, data.testCase)
      .catch(() => setMessage('다운로드를 할 수 없습니다.'));
  };

  const cellRender = ({data}: any) => {
    return <DownloadButton type='button' onClick={() => handleDownload(data)} />
  };

  return (
    <Container>
      <Breadcrumbs heading='다운로드' />
      
      <ContentBox>
        <SearchWrapper>
          <div>
            <input type='text' placeholder='실험 케이스를 입력하세요.' onChange={(event) => setKeyword(event.target.value)} onKeyDown={(event) => { if(event.key === 'Enter') handleSearch() }} />
            <PrimaryButton type='button' onClick={() => handleSearch()}>검색</PrimaryButton>
          </div>
        </SearchWrapper>

        <TableInfo total={total} pages={pages} page={page} sizes={sizes} size={size} handleSize={handleSize} />

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
          paging={{enabled: false}}
        >
          <Column caption='테스트 케이스' dataField='testCase' alignment='center' />
          <Column caption='시험 시작 시간' dataField='testStartAt' alignment='center' />
          <Column caption='	시험 종료 시간' dataField='testEndAt' alignment='center' />
          <Column caption='' dataField='listId' cellRender={cellRender} alignment='center' />
        </DataGrid>

        <Pagination page={page} pages={pages} size={size} handlePage={handlePage} />
      </ContentBox>

      { message &&
        <Dialog
          title=''
          handleClose={handleMessage}
        >
          <p>{ message }</p>
          <PrimaryButton onClick={() => handleMessage()}>확인</PrimaryButton>
        </Dialog>
      }
    </Container>
  );
}

export default DownloadPage;