import { useController, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form';
// styles
import { Label, TextareaFields, Message } from './styles';

// ----------------------------------------------------------------------

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement>{
  label: string;
};

// ----------------------------------------------------------------------

const TextareaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, control, label, ...other }: Props & UseControllerProps<TFieldValues, TName>) => {

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  return (
    <div>
      <Label>{ label }</Label>
      <TextareaFields {...other} {...field} $error={error ? true : false} />

      { error && error.type !== 'required' && error.type !== 'typeError' &&
        <Message>{ error.message }</Message>
      }
    </div>
  );
};

export default TextareaField;