import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const existsId = async (identification: string) => {
  return await axios.post('members/identification/exists', {identification})
    .then((response) => {
      return response.data.data.exists;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default existsId;