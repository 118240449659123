import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getData = async (setData: React.Dispatch<any>) => {
  return await axios.get('dashboards/test-data')
    .then((response) => {
      setData(response.data.data);
      return response.data.data.listId;
    })
    .catch(() => {});
};

export default getData;