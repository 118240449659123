import { breakpoints } from '../styles/theme';
import { useMediaQuery } from 'react-responsive';

// ----------------------------------------------------------------------

export default function useResponsive(query: string, size: string) {
  const mediaUp = useMediaQuery({ minWidth: breakpoints[size] });
  const mediaDown = useMediaQuery({ maxWidth: breakpoints[size] });

  if (query === 'up') return mediaUp;
  if (query === 'down') return mediaDown;
}