import { CSSProp } from 'styled-components';

// ----------------------------------------------------------------------

const colors = {
  grayscale: {
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#1C252E',
    900: '#141A21'
  },
  primary: {
    lighter: '#C8FAD6',
    light: '#5BE49B',
    main: '#00A76F',
    dark: '#007867',
    darker: '#004B50'
  },
  error: '#FF5630',
  info: '#00B8D9'
};

export const breakpoints: {[key: string]: number}  = {
  md: 1300,
  lg: 1700,
};

export const media = (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce(
  (acc, key) => {
    acc[key] = (style: string) => `@media screen and (max-width: ${breakpoints[key]}px) {
      ${style}
    }`
    return acc;
  },
  {} as { [key in keyof typeof breakpoints] : (style: string) => CSSProp}
);

const theme = { colors, media };

export default theme;
export type Theme = typeof theme;