import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const updateFan = async (fanWindSpeed: string) => {
  return await axios.put('dashboards/fan-speed', {fanWindSpeed})
    .catch((error) => {
      const code = error.response.data.code;

      let message = '환풍기 동작 전압을 변경할 수 없습니다.';
      switch( code ) {
        case 'ERR-103':
          message = '잠시 후 다시 시도해 주세요.'
          break;
        case 'ERR-107':
          message = '현재 진행중인 시험이 없습니다.'
      }

      return Promise.reject(
        new Error(message)
      );
    });
};

export default updateFan;