import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

export const token = createSlice({
  name: 'token',
  initialState: null,
  reducers: {
    SET_TOKEN: (state, action) => {
      return action.payload;
    },
    DELETE_TOKEN: () => {
      return null;
    }
  }
});
export const { SET_TOKEN, DELETE_TOKEN } = token.actions;

// ----------------------------------------------------------------------

export const account = createSlice({
  name: 'account',
  initialState: null,
  reducers: {
    SET_ACCOUNT: (state, action) => {
      return action.payload;
    },
    DELETE_ACCOUNT: () => {
      return null;
    }
  }
});

export const { SET_ACCOUNT, DELETE_ACCOUNT } = account.actions;

// ----------------------------------------------------------------------

export const loader = createSlice({
  name: 'loader',
  initialState: null,
  reducers: {
    SET_LOADER: () => {
      return true;
    },
    DELETE_LOADER: () => {
      return null;
    }
  }
});

export const { SET_LOADER, DELETE_LOADER } = loader.actions;