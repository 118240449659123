import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;

  .pagination {
    display: flex;

    > li {
      margin-left: 2px;
      &:first-child {
        margin-left: 0;
      }
    }

    a {
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      color: ${({theme}) => theme.colors.grayscale[800]};
      background-position: center center;
      background-repeat: no-repeat;
    }

    a:hover {
      background-color: ${({theme}) => theme.colors.grayscale[500]}1A;
    }

    .active a {
      font-weight: 600;
      color: ${({theme}) => theme.colors.primary.dark};
      background-color: ${({theme}) => theme.colors.primary.main}14;
    }

    .arrow a {
      background-image: url(/assets/arrow.svg);
      background-size: 7px 14px;
    }

    .double a {
      background-image: url(/assets/arrow-double.svg);
      background-size: 15px 14px;
    }

    .rotate a {
      transform: rotate(180deg);
    }
  }
`;