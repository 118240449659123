import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const stop = async () => {
  return await axios.post('dashboards/test-stop')
    .catch((error) => {
      const code = error.response.data.code;

      let message = '시험을 종료할 수 없습니다.';
      switch( code ) {
        case 'ERR-103':
          message = '잠시 후 다시 시도해 주세요.'
          break;
        case 'ERR-107':
          message = '현재 진행중인 시험이 없습니다.'
      }

      return Promise.reject(
        new Error(message)
      );
    });
};

export default stop;