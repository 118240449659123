import axios from '../_utils/axios';
// store
import store, { SET_ACCOUNT } from '../../store';

// ----------------------------------------------------------------------

const getAccount = async () => {
  return await axios.get('accounts/me')
    .then((response) => {
      store.dispatch( SET_ACCOUNT(response.data.data) );
    })
    .catch(() => {});
};

export default getAccount;