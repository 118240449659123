import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const unlockMembers = async (memberId: string) => {
  return await axios.put(`members/${memberId}/unlock`)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default unlockMembers;