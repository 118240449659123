import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
// store
import { useSelector } from '../store';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Dialog from '../components/dialog';
import { PasswordField } from '../components/hook-form';
import Loaders from '../components/loaders';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// utils
import { REGEXP_PASSWORD } from '../utils/regexp';
// styles
import { Header, Link, Main } from './styles';
import { Message, ButtonWrapper, PrimaryButton, DefaultButton } from '../styles';
// apis
import { getAccount, logout, changePassword } from '../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  current: yup.string()
    .required(),
  new: yup.string()
    .required()
    .matches(REGEXP_PASSWORD, '영문, 숫자, 8-16자, 특수기호~!@#$%^&*()+|=를 사용해 주세요.'),
  confirm: yup.string()
    .required()
    .oneOf([yup.ref('new'), null], '비밀번호가 일치하지 않습니다.')
});

interface FormValues {
  current?: string;
  new?: string;
};

// ----------------------------------------------------------------------

function Layout() {
  const navigate  = useNavigate();
  const location  = useLocation();
  const pathname = '/' + location.pathname.split('/')[1];
  const account = useSelector((state) => state.account);
  const loader = useSelector((state) => state.loader);
  const mobile = useResponsive('down', 'md');

  const {
    control,
    reset,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      current: '',
      new: '',
      confirm: ''
    }
  });

  const [ visible, setVisible ] = useState(false);
  const [ dialog, setDialog ] = useState(false);
  const [ message, setMessage ] = useState(null);
  const [ success, setSuccess ] = useState(false);

  useEffect(() => {
    if ( !account ) ( async () => await getAccount() )();
  }, [account]);

  useEffect(() => {
    if ( location.state?.submit ) {
      const handleEvent = () => window.history.pushState(null, '', window.location.href);
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handleEvent);
      return () => window.removeEventListener('popstate', handleEvent);
    }
  }, [location]);

  useEffect(() => {
    if ( pathname ==='/members' && account?.role !== 'ADMIN' ) return navigate(-1);
  }, [pathname]);

  const handleClose = () => {
    setDialog(false)
    setMessage(null);
    reset();
  };

  const handleSuccess = () => setSuccess(false);

  const onSubmit = async (data: FormValues) => {
    await changePassword(data.current, data.new)
      .then(() => {
        handleClose();
        setSuccess(true);
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  if ( !account ) return;
  return (
    <>
      { loader &&
        <Loaders />
      }
      <Header>
        <div>
          <div>Lab Scale Tester</div>
          { !mobile &&
            <nav>
              <Link to='/dashboard' $active={pathname === '/dashboard' ? true : false}>대시보드</Link>
              <Link to='/download' $active={pathname === '/download' ? true : false}>다운로드</Link>
              { account?.role === 'ADMIN' &&
                <Link to='/members' $active={pathname === '/members' ? true : false}>사용자 관리</Link>
              }
            </nav>
          }
        </div>
        <div>
          <button type='button' id='account' onClick={() => setVisible(!visible)} />
          <Popover
            target='#account'
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={() => setVisible(false)}
          >
            <button onClick={() => {
              setVisible(false);
              setDialog(true);
            }}>
              보안 설정
            </button>
            <button type='button' onClick={() => logout()}>로그아웃</button>
          </Popover>
        </div>
      </Header>

      { dialog &&
        <Dialog
          title='보안 설정'
          handleClose={handleClose}
        >
          <form onSubmit={ handleSubmit(onSubmit) }>
            <PasswordField
              name='current'
              control={control}
              label='현재 비밀번호'
              autoComplete='current-password'
            />

            <PasswordField
              name='new'
              label='새 비밀번호'
              control={control}
              autoComplete='new-password'
            />

            <PasswordField
              name='confirm'
              label='비밀번호 확인'
              control={control}
              autoComplete='new-password'
            />

            <Message>{ message }</Message>
            
            <ButtonWrapper>
              <PrimaryButton type='submit'>변경</PrimaryButton>
              <DefaultButton type='button' onClick={() => handleClose()}>취소</DefaultButton>
            </ButtonWrapper>
          </form>
        </Dialog>
      }

      { success &&
        <Dialog
          title='비밀번호 변경 완료'
          handleClose={handleSuccess}
        >
          <p>비밀번호가 성공적으로 변경되었습니다.</p>   
          <PrimaryButton onClick={() => handleSuccess()}>확인</PrimaryButton>
        </Dialog>
      }

      <Main>
        <Outlet />
      </Main>
    </>
  );
}

export default Layout;