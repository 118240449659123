import styled, { css } from 'styled-components';

// ----------------------------------------------------------------------

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > div {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${({theme}) => theme.colors.grayscale[700]};
    margin-bottom: 50px;
  }
  
  > form > button {
    margin-top: 20px;
  }
`;

export const Container = styled.div`
  width: 1280px;
  padding: 40px;
`;

export const ContentBox = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  padding-bottom: 30px;
`;

export const FormContainer = styled(Container)`
  ${ContentBox} {
    width: fit-content;
    padding: 30px;
    margin: 0 auto;
  }
`;

/* BUTTON */
export const PrimaryButton = styled.button`
  width: 100%;
  height: 45px;
  font-size: 15px;
  color: #FFFFFF;
  font-weight: 600;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.primary.main};

  &:hover, &:active {
    background-color: ${({theme}) => theme.colors.primary.dark};
  }
  &:disabled {
    color: ${({theme}) => theme.colors.grayscale[500]}CC;
    background-color: ${({theme}) => theme.colors.grayscale[500]}33;
  }
`;

export const DefaultButton = styled.button`
  height: 45px;
  font-size: 15px;
  color: ${({theme}) => theme.colors.grayscale[800]};
  font-weight: 600;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.grayscale[500]}52;

  &:hover, &:active {
    background-color: ${({theme}) => theme.colors.grayscale[500]}0D;
  }
  &:disabled {
    color: ${({theme}) => theme.colors.grayscale[500]}CC;
    background-color: ${({theme}) => theme.colors.grayscale[500]}33;
    border: none;
  }
`;

export const LineButton = styled.button`
  height: 45px;
  font-size: 15px;
  color: ${({theme}) => theme.colors.primary.main};
  font-weight: 500;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.primary.main}7A;

  &:hover, &:active {
    background-color: ${({theme}) => theme.colors.primary.main}14;
  }
`;

export const IconButtonStyles = css`
  width: 36px;
  height: 36px;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  display: block;
  margin: 0 auto;

  &:hover, &:active {
    background-color: ${({theme}) => theme.colors.grayscale[500]}1A;
  }

  &:disabled {
    opacity: 0.3;
    background-color: transparent;
  }
`;

export const DownloadButton = styled.button`
  background-image: url(/assets/download.svg);
  background-size: 24px 24px;
  ${ IconButtonStyles }
`;

export const MoreButton = styled.button`
  background-image: url(/assets/more.svg);
  background-size: 4px 20px;
  ${ IconButtonStyles }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > button {
    width: 49%;
  }
`;

export const SearchWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 300px;
    height: 40px;
    padding: 0 15px;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.grayscale[500]}80;
    margin-right: 15px;
  }
  
  select {
    margin-right: 15px;
  }
  
  ${PrimaryButton} {
    width: auto;
    height: 40px;
  }
  
  ${LineButton} {
    height: 40px;
  }
`;

/* DASHBOARD */
export const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Dashboard = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 1200px;
  }

  > div:first-child {
    text-align: left;
  }

  > div:last-child {
    display: flex;
    margin-top: 30px;
    gap: 30px;

    > div {
      padding: 0;

      > p {
        padding: 25px 30px;
        font-size: 20px;
        font-weight: 500;
        color: ${({theme}) => theme.colors.grayscale[700]};
        border-bottom: 1px solid ${({theme}) => theme.colors.grayscale[500]}33;
      }

      > div {
        display: flex;
        margin: 30px 30px 0;
        padding-bottom: 30px;
        border-bottom: 1px dashed ${({theme}) => theme.colors.grayscale[500]}33;

        &:last-child {
          border-bottom: none;
        }

        > p {
          width: 50%;
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }

  ${({theme}) => theme.media.lg`
    padding: 40px 20px;

    > div {
      width: 100%;
    }

    > div:first-child > p {
      width: 100%;
      text-align: left;
    }
  `};

  ${({theme}) => theme.media.md`
    > button {
      margin-bottom: 30px;
    }

    > div:last-child {
      display: block;
      margin-top: 0;

      p {
        width: 100%;
        text-align: left;
      }

      > div {
        margin-top: 30px;

        > div > p {
          font-size: 14px;
        }
      }
    }
  `};
`;

export const DashboardContent = styled(ContentBox)`
  padding: 30px;
  display: flex;

  > div {
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px dashed ${({theme}) => theme.colors.grayscale[500]}33;
    font-size: 15px;
    text-align: center;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
    }
      
    > p {
      margin-bottom: 10px;
    }
  }

  ${({theme}) => theme.media.md`
    display: block;

    > div {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
      display: flex;
      align-items: center;
      height: 55px;
      border-bottom: 1px dashed #919EAB33;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
      p, span {
        width: 50%;
        text-align: center;
        margin-bottom: 0;
      }
    }
  `};
`;

export const Controls = styled.div`
  width: 400px;
  height: calc(100vh - 60px);
  border-left: 1px dashed ${({theme}) => theme.colors.grayscale[500]}33;
  padding: 40px 30px;

  > p:first-child {
    font-size: 20px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.grayscale[700]};
    padding-bottom: 30px;
  }

  form {
    width: 100%;
  }
  
  > div {
    margin-top: 20px;
  }
    
  ${ButtonWrapper} {
    margin-top: 50px;
  }

  ${({theme}) => theme.media.lg`
    padding: 40px 20px;
  `};
`;

export const Message = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({theme}) => theme.colors.error};
`;