import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// components
import Breadcrumbs from '../components/breadcrumbs';
import { ButtonField, SelectField } from '../components/hook-form';
import Dialog from '../components/dialog';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_ID, REGEXP_ID_START } from '../utils/regexp';
// styles
import { FormContainer, ContentBox, ButtonWrapper, PrimaryButton, DefaultButton, Message } from '../styles';
// apis
import { existsId, createMembers, updateMembers } from '../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  identification: yup.string()
    .required()
    .matches(REGEXP_ID, '영문 소문자, 숫자, 3-20자, 특수기호-_를 사용해 주세요.')
    .matches(REGEXP_ID_START, '영문 소문자로 시작해 주세요.'),
  role: yup.string()
    .required()
});

interface FormValues {
  identification?: string;
  role?: string;
};

const ROLE: {[key: string]: string} = { '사용자': 'USER', '관리자': 'ADMIN' };

// ----------------------------------------------------------------------

function MembersFormPage() {
  const navigate  = useNavigate();
  const create = useLocation().pathname.split('/')[2] === 'create' ? true : false;
  const state = useLocation().state;

  const {
    control,
    trigger,
    getValues,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      identification: state?.identification || '',
      role: state? ROLE[state.role] : 'USER'
    }
  });

  const [ check, setCheck ] = useState({result: null, message: null});
  const [ checked, setChecked ] = useState(null);
  
  const [ message, setMessage ] = useState(null);
  const [ success, setSuccess ] = useState({result: false, answer: null });

  useEffect(() => {
    const value = watch('identification');
    if ( value !== checked && check.message ) {
      setCheck({result: null, message: null});
      setChecked(null);
    }
  }, [ watch('identification') ]);

  const handleIdentification = async () => {
    const value = getValues('identification');
    if ( !REGEXP_ID.test(value) ) {
      trigger();
    } else {
      await existsId(value)
        .then((response) => {
          setCheck({result: response, message: response ? '이미 등록된 아이디입니다.' : '사용하실 수 있는 아이디입니다.'});
          setChecked(value);
        })
        .catch(() => {
          setCheck({result: null, message: '나중에 다시 시도해 주세요.'});
        });
    }
  };

  const handleClose = () => {
    setSuccess({result: false, answer: null });
    navigate('/members', {state: {submit: true}});
  };

  const onSubmit = async (data: FormValues) => {
    if ( create ) {
      if ( check.result === null || checked !== data.identification ) return setCheck({result: null, message: '중복 체크 후 진행하실 수 있습니다.'});
      await createMembers(data.identification, data.role)
        .then((response) => setSuccess({result: true, answer: response }))
        .catch(() => setMessage('사용자 등록을 할 수 없습니다. 나중에 다시 시도해 주세요.'));
    } else {
      await updateMembers(state.memberId, data.role)
        .then(() => navigate('/members', {state: {submit: true}}))
        .catch(() => setMessage('사용자 수정을 할 수 없습니다. 나중에 다시 시도해 주세요.'));
    }
  };

  return (
    <FormContainer>
      <Breadcrumbs
        heading={`사용자 ${create ? '등록' : '수정'}`}
        links={[
          {name: '사용자 관리', link: '/members'},
          {name: `사용자 ${create ? '등록' : '수정'}`}
        ]}
      />

      <ContentBox>
        <form onSubmit={ handleSubmit(onSubmit) }>
          <ButtonField
            name='identification'
            label='아이디'
            control={control}
            action={
              <DefaultButton type='button' onClick={() => handleIdentification()} disabled={ create ? false : true }>중복 체크</DefaultButton>
            }
            exists={check}
            disabled={ create ? false : true }
          />

          <SelectField
            name='role'
            label='권한'
            option={[
              {value: 'USER', name: '사용자'},
              {value: 'ADMIN', name: '관리자'}
            ]}
            control={control}
          />

          <Message>{ message }</Message>

          <ButtonWrapper>
            <PrimaryButton type='submit'>{ create ? '등록' : '수정' }</PrimaryButton>
            <DefaultButton type='button' onClick={() => navigate('/members')}>취소</DefaultButton>
          </ButtonWrapper>

        </form>

        { success.result &&
          <Dialog
            title='사용자 등록 완료'
            handleClose={handleClose}
          >
            <p>
              아이디 : { success.answer.identification }<br/>
              임시 비밀번호 : { success.answer.temporaryPassword }
            </p>
            <PrimaryButton onClick={() => handleClose()}>확인</PrimaryButton>
          </Dialog>
        }
      </ContentBox>
    </FormContainer>
  );
}

export default MembersFormPage;