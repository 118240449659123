import { useNavigate } from 'react-router-dom';
// styles
import { Wrapper } from './styles';

// ----------------------------------------------------------------------

interface Props {
  heading: string;
  links?: {
    name: string;
    link?: string;
  }[];
};

// ----------------------------------------------------------------------

export default function Breadcrumbs({ heading, links }: Props) {
  const navigate  = useNavigate();

  const breadcrumbs = links?.map((item) => {
    if ( item.link ) {
      return <li key={item.name}><button onClick={() => navigate(item.link)}>{ item.name }</button></li>;
    } else {
      return <li key={item.name}>{ item.name }</li>;
    }
  })

  return (
    <Wrapper>
      { links &&
        <ul>{ breadcrumbs }</ul>
      }
      <p>{ heading }</p>
    </Wrapper>
  );
}