import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { Wrapper, RowsPerPage } from './styles';

// ----------------------------------------------------------------------

interface Props {
  total: number;
  pages: number;
  page: number;
  sizes: number[];
  size: number;
  handleSize: (value: number) => void;
};

// ----------------------------------------------------------------------

export default function TableInfo({ total, pages, page, sizes, size, handleSize }: Props) {

  const [ visible, setVisible ] = useState(false);

  return (
    <Wrapper>
      <p>· 총 <span>{total}</span>건 검색</p>
      <div>
        <p>· 총 {pages} / <span>{page+1}</span> 페이지</p>
        <button type='button' id='rows' onClick={() => setVisible(!visible)}><div />목록</button>
        <Popover
          target='#rows'
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
        >
          <RowsPerPage>
            <div>
              <p>페이지 당 목록</p>
              <button type='button' onClick={() => setVisible(false)} />
            </div>
            { sizes.map((item) => (
              <label key={item}>
                <input type='radio' name='size' value={item} checked={item === size ? true : false} onChange={(event) => handleSize(Number(event.target.value))}/>
                <p>{ item }</p>
              </label>
            ))}
          </RowsPerPage>
        </Popover>
      </div>
    </Wrapper>
  );
}