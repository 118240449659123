import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const decode = (disposition: string) => {
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, '')
  );
  return fileName.normalize('NFC');
};

// ----------------------------------------------------------------------

const download = async ( id: string, name: string ) => {
  return await axios.get(`downloads/test-list/${id}/download`, { responseType: 'blob' })
    .then((response) => {
      const url = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;
      link.style.display = 'none';
      
      link.download = decode(response.headers['content-disposition']);
      
      document.body.appendChild(link);
      link.click();
      link.remove();
      
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default download;