import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getStatus = async (setStatus: React.Dispatch<any>) => {
  return await axios.get('dashboards/test-status')
    .then((response) => {
      setStatus(response.data.data);
      return response.data.data.listId;
    })
    .catch(() => {});
};

export default getStatus;