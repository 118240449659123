import axios, { InternalAxiosRequestConfig } from 'axios';
import store, { SET_TOKEN } from '../../store';

// ----------------------------------------------------------------------

const reissue = async ( config: InternalAxiosRequestConfig ) => {
  return await axios.post('/api/auth/reissue')
    .then((response) => {
      const token = response.data.data.accessToken;
      config.headers.Authorization = `Bearer ${token}`;
      store.dispatch( SET_TOKEN(token) );
    })
    .catch(() => {
      return window.location.href = '/';
    });
};

export default reissue;