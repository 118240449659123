import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// store
import { useSelector } from '../store';
// components
import Breadcrumbs from '../components/breadcrumbs';
import TableInfo from '../components/table-info';
import Pagination from '../components/pagination';
import Dialog from '../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Popover } from 'devextreme-react/popover';
// styles
import { Container, ContentBox, SearchWrapper, PrimaryButton, LineButton, DefaultButton, MoreButton, ButtonWrapper } from '../styles';
// apis
import { getMembers, deleteMembers, unlockMembers, issuancePassword } from '../apis';

// ----------------------------------------------------------------------

const sizes = [10, 20, 30];

// ----------------------------------------------------------------------

function MembersPage() {
  const navigate  = useNavigate();
  const identification = useSelector((state) => state.account.identification);

  const [ data, setData ] = useState({ content: [], totalElements: 0, totalPages: 1 });
  const { content: rows, totalElements: total, totalPages: pages } = data;

  const [ keyword, setKeyword ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  useEffect(() => {
    handleSearch();
  }, []);

  const handlePage = (value: number) => {
    setPage(value-1);
    getMembers(setData, keyword, value-1, size);
  };

  const handleSize = (value: number) => {
    setPage(0);
    setSize(value);
    getMembers(setData, keyword, 0, value);
  };

  const handleSearch = () => {
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    setPage(0);
    getMembers(setData, keyword, 0, size);
  };

  const handleReset = () => {
    setKeyword('');
    setPage(0);
    getMembers(setData, keyword, 0, size);
  };

  return (
    <Container>
      <Breadcrumbs heading='사용자 관리' />
      
      <ContentBox>
        <SearchWrapper>
          <div>
            <input type='text' placeholder='아이디를 입력하세요.' onChange={(event) => setKeyword(event.target.value)} onKeyDown={(event) => { if(event.key === 'Enter') handleSearch() }} />
            <PrimaryButton type='button' onClick={() => handleSearch()}>검색</PrimaryButton>
          </div>
          <LineButton onClick={() => navigate('create')}>등록</LineButton>
        </SearchWrapper>

        <TableInfo total={total} pages={pages} page={page} sizes={sizes} size={size} handleSize={handleSize} />

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
          paging={{enabled: false}}
          dataRowRender={({data}) => <TableRow row={data} identification={identification} handleReset={handleReset} />}
        >
          <Column caption='아이디' dataField='identification' alignment='center' />
          <Column caption='권한' dataField='role' alignment='center' />
          <Column caption='로그인 차단' dataField='locked' alignment='center' />
          <Column caption='등록일' dataField='createdAt' alignment='center' />
          <Column />
        </DataGrid>

        <Pagination page={page} pages={pages} size={size} handlePage={handlePage} />
      </ContentBox>
    </Container>
  );
}

export default MembersPage;

// ----------------------------------------------------------------------

interface Props {
  row: {
    memberId: string;
    identification: string;
    role: string;
    locked: boolean;
    createdAt: string;
  };
  identification: string;
  handleReset: () => void;
};

// ----------------------------------------------------------------------

function TableRow({ row, identification, handleReset }: Props) {
  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);
  const [ dialog, setDialog ] = useState({ visible: false, type: null, title: null });
  const [ password, setPassword ] = useState(null);
  const [ message, setMessage ] = useState(null);

  const handleClose = () => setDialog({ visible: false, type: null, title: null });
  const handleMessage = () => setMessage(null);
  const handlePassword = () => setPassword(null);

  const handleActions = async () => {
    if ( dialog.type === 'delete' ) {
      await deleteMembers(row.memberId)
        .then(() => handleReset())
        .catch(() => setMessage('사용자 계정을 삭제할 수 없습니다.\n나중에 다시 시도해 주세요.'))
        .finally(() => handleClose());
    } else {
      await unlockMembers(row.memberId)
        .then(() => handleReset())
        .catch(() => setMessage('사용자 계정의 차단을 해제할 수 없습니다.\n나중에 다시 시도해 주세요.'))
        .finally(() => handleClose());
    }
  };

  const issuance = async () => {
    await issuancePassword(row.memberId)
      .then((response) => setPassword('임시 비밀번호 : ' + response))
      .catch(() => setPassword('임시 비밀번호를 발급할 수 없습니다.\n나중에 다시 시도해 주세요.'));
  };

  return (
    <tr>
      <td>{ row.identification }</td>
      <td>{ row.role }</td>
      <td>{ row.locked ? '차단' : '미차단' }</td>
      <td>{ row.createdAt }</td>
      <td>
        <MoreButton id={`more${row.memberId}`} onClick={() => setVisible(!visible)} disabled={row.identification === identification ? true : false} />
        <Popover
          target={`#more${row.memberId}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button onClick={() => navigate('update', {state: row})}>수정</button>
          <button onClick={() => {
            setVisible(false);
            setDialog({ visible: true, type: 'delete', title: '삭제' });
          }}>
            삭제
          </button>
          { row.locked &&
            <button onClick={() => {
              setVisible(false);
              setDialog({ visible: true, type: 'unlock', title: '차단 해제' });
            }}>
              차단 해제
            </button>
          }
          <button onClick={() => {
            setVisible(false);
            issuance();
          }}>
            임시 비밀번호 발급
          </button>
        </Popover>

        { dialog.visible &&
          <Dialog
            title={`사용자 계정 ${ dialog.title }`}
            handleClose={handleClose}
          >
            <p>
              { dialog.type === 'delete' ?
                '선택하신 사용자 계정을 삭제하시겠습니까?'
                : '선택하신 사용자 계정의 차단을 해제하시겠습니까?'
              }
            </p>
            <ButtonWrapper>
              <PrimaryButton onClick={() => handleActions()}>{ dialog.title }</PrimaryButton>
              <DefaultButton onClick={() => setDialog({ visible: false, type: null, title: null })}>취소</DefaultButton>
            </ButtonWrapper>
          </Dialog>
        }

        { message &&
          <Dialog
            title=''
            handleClose={handleMessage}
          >
            <p>{ message }</p>
            <PrimaryButton onClick={() => handleMessage()}>확인</PrimaryButton>
          </Dialog>
        }

        { password &&
          <Dialog
            title='임시 비밀번호 발급'
            handleClose={handlePassword}
          >
            <p>{ password }</p>
            <PrimaryButton onClick={() => handlePassword()}>확인</PrimaryButton>
          </Dialog>
        }
      </td>
    </tr>
  );
}