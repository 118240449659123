import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const getDownloads = async (
  setData: React.Dispatch<React.SetStateAction<{
    content: any[];
    totalElements: number;
    totalPages: number;
  }>>,
  testCase: string,
  page: number,
  size: number
) => {
  return await axios.get(`downloads/test-list/search-page?testCase=${testCase}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getDownloads;