import styled from 'styled-components';
import { IconButtonStyles } from '../../styles';

// ----------------------------------------------------------------------

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

export const Wrapper = styled.div`
  min-width: 300px;
  max-height: calc(100% - 20px);
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 30px;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      font-size: 18px;
      font-weight: 600;
    }
    
    > button {
      ${ IconButtonStyles }
      margin: 0;
      background-image: url(/assets/close.svg);
      background-size: 100% auto;
    }
  }

  > div:last-child > button {
    height: 40px;
  }
    
  ${({theme}) => theme.media.md`
    width: calc(100% - 10px);
    form {
      width: 100%;
    }
  `};
`;

export const Content = styled.div`
  margin-top: 30px;

  > p {
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const Message = styled.p`
  white-space: pre-wrap;
  margin-bottom: 10px;
`;