import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  margin-bottom: 30px;

  ul {
    display: flex;
    margin-bottom: 5px;
    font-size: 13px;

    li:after {
      content: '·';
      padding: 0 3px;
    }
    li:last-child:after {
      display: none;
    }
    
    button {
      border: none;
    }
  }

  > p {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    color: ${({theme}) => theme.colors.grayscale[800]};
  }
`;