// styles
import { Overlay, Wrapper, Content, Message } from './styles';

// ----------------------------------------------------------------------

interface Props {
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
};

// ----------------------------------------------------------------------

export default function Dialog({ handleClose, title, children }: Props){
  return (
    <Overlay>
      <Wrapper>
        <div>
          <p>{ title }</p>
          <button type='button' onClick={() => handleClose()} />
        </div>

        <Content>
          { children }
        </Content>

      </Wrapper>
    </Overlay>
  );
}