import { createGlobalStyle } from 'styled-components';

// ----------------------------------------------------------------------

const GlobalStyles = createGlobalStyle`

  *, :after, :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    color: ${({theme}) => theme.colors.grayscale[600]};
  }

  ul, li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.grayscale[600]};
  }

  form {
    width: 360px;

    > div {
      margin-bottom: 20px;

      &:nth-last-child(2) {
        margin-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-top: 25px;
      }
    }
  }

  input, button, select, textarea {
    font-size: inherit;
    font-family: inherit;
    background-color: transparent;
    outline: none;
  }

  input, select, textarea {
    color: ${({theme}) => theme.colors.grayscale[800]};
  }

  input::placeholder, input:disabled, select:disabled, textarea:disabled {
    color: ${({theme}) => theme.colors.grayscale[500]};
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid ${({theme}) => theme.colors.grayscale[600]};
    border-radius: 50%;
  }
  input[type='radio']:checked {
    border: 4px solid ${({theme}) => theme.colors.primary.main};
    box-shadow: 0 0 0 1px ${({theme}) => theme.colors.primary.main};
  }

  select {
    height: 40px;
    padding: 0 15px;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.grayscale[500]}80;
  }
  
  button {
    cursor: pointer;
    color: ${({theme}) => theme.colors.grayscale[600]};
  }
  
  button:disabled {
    cursor: default;
  }

  td {
    text-align: center;
  }

  p {
    white-space: pre-wrap;
  }
`;

export default GlobalStyles;