import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const start = async ( testCase: string, memo: string, fanWindSpeed: string ) => {
  return await axios.post('dashboards/test-start', {testCase, memo, fanWindSpeed})
    .catch((error) => {
      const code = error.response.data.code;

      let message = '시험을 시작할 수 없습니다.';
      switch( code ) {
        case 'ERR-102':
          message = '현재 시험이 진행 중입니다.\n시험을 종료하시고 실행해 주세요.'
          break;
        case 'ERR-103':
          message = '잠시 후 다시 시도해 주세요.'
      }

      return Promise.reject(
        new Error(message)
      );
    });
};

export default start;