import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const changePassword = async ( currentPassword: string, newPassword: string ) => {
  return await axios.put('accounts/password', {currentPassword, newPassword})
    .catch((error) => {
      const code = error.response.data.code;
      return Promise.reject(
        new Error( code === 'ERR-024' ?
          '비밀번호를 다시 한번 확인해 주세요.'
          : '비밀번호를 변경할 수 없습니다. 나중에 다시 시도해 주세요.'
        )
      );
    });
};

export default changePassword;