import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useSelector as useAppSelector, useDispatch as useAppDispatch, TypedUseSelectorHook } from 'react-redux';
// slices
import { token, account, loader } from './slices';

// ----------------------------------------------------------------------

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
export const useDispatch: () => AppDispatch = useAppDispatch;

export { SET_TOKEN, DELETE_TOKEN, SET_ACCOUNT, DELETE_ACCOUNT, SET_LOADER, DELETE_LOADER } from './slices';

// ----------------------------------------------------------------------

const reducer = combineReducers({
  token: token.reducer,
  account: account.reducer,
  loader: loader.reducer
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export default store;