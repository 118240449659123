import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Label = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: ${({theme}) => theme.colors.grayscale[600]};
  margin-bottom: 3px;
`;

export const TextFields = styled.input<{ $error: boolean }>`
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.grayscale[500]+'80' };

  &:disabled {
    background-color: ${({theme}) => theme.colors.grayscale[100]};
  }
`;

export const ButtonFields = styled.div<{ $error: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > input {
    width: calc(100% - 110px);
    height: 45px;
    padding: 0 15px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.grayscale[500]+'80' };

    &:disabled {
      background-color: ${({theme}) => theme.colors.grayscale[100]};
    }
  }

  > button {
    width: 100px;
  }
`;

export const PasswordFields = styled.div<{ $error: boolean,  $icon: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.grayscale[500]+'80' };

  > input {
    width: calc(100% - 39px);
    height: 45px;
    padding: 0 10px 0 15px;
    border: none;
  }

  > button {
    width: 24px;
    height: 24px;
    background-image: url(/assets/${(props) => props.$icon}.svg);
    background-size: 18px auto;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
  }
`;

export const SelectFields = styled.div<{ $error: boolean, $action: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  select {
    width: ${(props) => props.$action ? 'calc(100% - 110px)' : '100%' };
    height: 45px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.grayscale[500]+'80' };

    &:disabled {
      background-color: ${({theme}) => theme.colors.grayscale[100]};
    }
  }

  > button {
    width: 100px;
  }
`;

export const Message = styled.p<{ type?: string }>`
  font-size: 12px;
  font-weight: 500;
  white-space: pre-wrap;
  padding: 3px 0 0 3px;
  color: ${(props) => props.type ? props.theme.colors[props.type] : props.theme.colors.error};
`;

export const TextareaFields = styled.textarea<{ $error: boolean }>`
  width: 100%;
  height: 100px;
  padding: 15px;
  resize: none;
  border-radius: 8px;
  border: 1px solid ${(props) => props.$error ? props.theme.colors.error : props.theme.colors.grayscale[500]+'80' };

  &:disabled {
    background-color: ${({theme}) => theme.colors.grayscale[100]};
  }
`;