import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div`
  width: 100%;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 13.5px;
    color: ${({theme}) => theme.colors.grayscale[600]};

    > span {
      color: ${({theme}) => theme.colors.grayscale[700]};
      font-weight: 600;
    }
  }

  > div {
    display: flex;
    align-items: center;

    > button {
      width: 75px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      border-radius: 8px;
      border: none;
      color: ${({theme}) => theme.colors.grayscale[600]};

      > div {
        width: 24px;
        height: 24px;
        background-image: url(/assets/rows.svg);
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 4px;
      }

      &:hover, &:active {
        color: ${({theme}) => theme.colors.grayscale[700]};
        border: 1px solid ${({theme}) => theme.colors.grayscale[500]}52;
        background-color: ${({theme}) => theme.colors.grayscale[500]}0D;
      }
    }
  }
`;

export const RowsPerPage = styled.div`
  > div:first-child {
    padding: 2px 10px 8px;
    margin-bottom: 8px;
    display: flex;
    border-bottom: 1px dashed ${({theme}) => theme.colors.grayscale[500]}52;

    > p {
      font-size: 14.5px;
      font-weight: 500;
      line-height: 24px;
    }

    > button {
      width: 24px;
      height: 24px;
      padding: 0;
      border: none;
      border-radius: 50%;
      background-image: url(/assets/close.svg);
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
      margin-left: 20px;
    }
  }
  
  label {
    display: block;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    &:hover, &:active {
      background-color: ${({theme}) => theme.colors.grayscale[500]}0D;
    }
    
    > p {
      line-height: 14px;
      margin-left: 10px;
    }
  }
`;