import { useState, useEffect } from 'react';
// @form
import { useController, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form';
// styles
import { Label, PasswordFields, Message } from './styles';

// ----------------------------------------------------------------------

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
};

// ----------------------------------------------------------------------

const PasswordField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name, control, label, ...other
}: Props & UseControllerProps<TFieldValues, TName>) => {

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control
  });

  const [ type, setType ] = useState('password');

  const [ errors, setErrors ] = useState(false);

  useEffect(() => {
    if ( error ) {
      if ( name === 'password' && error.type !== 'required' ) {
        return setErrors(false); 
      }
      return setErrors(true); 
    }
    setErrors(false); 
  }, [error]);

  return (
    <div>
      <Label>{ label }</Label>

      <PasswordFields $error={errors} $icon={type === 'text' ? 'eye-closed' : 'eye-open'}>
        <input type={type} {...other} {...field} />
        <button type='button' onClick={() => type === 'text' ? setType('password') : setType('text') } />
      </PasswordFields>

      { error && error.type !== 'required' && name !== 'password' &&
        <Message>{ error.message }</Message>
      }
    </div>
  );
};

export default PasswordField;