import { useState, useEffect } from 'react';
// @form
import { useController, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form';
// styles
import { Label, TextFields, Message } from './styles';

// ----------------------------------------------------------------------

interface Props extends React.InputHTMLAttributes<HTMLInputElement>{
  label: string;
};

// ----------------------------------------------------------------------

const TextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, control, label, ...other }: Props & UseControllerProps<TFieldValues, TName>) => {

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  const [ errors, setErrors ] = useState(false);

  useEffect(() => {
    if ( error ) {
      if ( name === 'id' && error.type !== 'required' ) {
        return setErrors(false); 
      }
      return setErrors(true); 
    }
    setErrors(false); 
  }, [error]);

  return (
    <div>
      <Label>{ label }</Label>
      <TextFields {...other} {...field} $error={errors} />

      { error && error.type !== 'required' && error.type !== 'typeError' && name !== 'id' &&
        <Message>{ error.message }</Message>
      }
    </div>
  );
};

export default TextField;