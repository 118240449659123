import axios from 'axios';
// store
import store, { SET_TOKEN } from '../../store';

// ----------------------------------------------------------------------

const login = async ( identification: string, password: string ) => {
  return await axios.post('/api/auth/login', { identification, password })
    .then((response) => {
      store.dispatch( SET_TOKEN(response.data.data.token.accessToken) );
    })
    .catch((error) => {
      const code = error.response.data.code;
      return Promise.reject(
        new Error( code === 'ERR-023' ?
          '로그인 시도 횟수를 초과하셨습니다.\n관리자에게 문의해 주세요.'
          : '아이디 또는 비밀번호를 다시 한번 확인해 주세요.'
        )
      );
    });
};

export default login;