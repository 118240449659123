import { useController, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form';
// styles
import { Label, SelectFields } from './styles';

// ----------------------------------------------------------------------

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  option: { value: string | number; name: string; }[];
  action?: JSX.Element;
};

// ----------------------------------------------------------------------

const SelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, control, label, option, action, ...other }: Props & UseControllerProps<TFieldValues, TName>) => {

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  return (
    <div>
      <Label>{ label }</Label>
      <SelectFields $error={error ? true : false} $action={action ? true : false}>
        <select {...other} {...field}>
          { option.map((item) => (
            <option key={ item.value } value={ item.value }>{ item.name }</option>
          ))}
        </select>
        { action }
      </SelectFields>
    </div>
  );
};

export default SelectField;