import { useEffect } from 'react';
// @pagination
import ReactPagination from 'react-js-pagination';
// styles
import { Wrapper } from './styles';

// ----------------------------------------------------------------------

interface Props {
  page: number;
  pages: number;
  size: number;
  handlePage: (value: number) => void;
};

// ----------------------------------------------------------------------

export default function Pagination({ page, pages, size, handlePage }: Props) {

  useEffect(() => {
    if ( window.scrollY === 0 ) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  return (
    <Wrapper>
      <ReactPagination
        activePage={page + 1}
        itemsCountPerPage={size}
        totalItemsCount={size * pages}
        pageRangeDisplayed={5}
        onChange={handlePage}
        itemClassFirst='double'
        itemClassPrev='arrow'
        itemClassNext='arrow rotate'
        itemClassLast='double rotate'
        prevPageText=''
        firstPageText=''
        lastPageText=''
        nextPageText=''
      />
    </Wrapper>
  );
}