import { useRoutes } from 'react-router-dom';

// ----------------------------------------------------------------------

import Layout from '../layouts';

// ----------------------------------------------------------------------

import LoginPage from '../pages/LoginPage';
import DashboardPage from '../pages/DashboardPage';
import DownloadPage from '../pages/DownloadPage';
import MembersPage from '../pages/MembersPage';
import MembersFormPage from '../pages/MembersFormPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    { index: true, element: <LoginPage /> },

    { element: <Layout />, children: [
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'download', element: <DownloadPage /> },
      
      { path: 'members',
        children: [
          { index: true, element: <MembersPage /> },
          { path: 'create', element: <MembersFormPage /> },
          { path: 'update', element: <MembersFormPage /> }
        ]
      },
    ]}
  ]);
  return routes;
}